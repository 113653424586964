import HeroBlockModel from '../../HeroBlock/Models/HeroBlockModel.interface';
import { styled } from '../../stitches.config';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '../../Atoms/Typography/Headings/Heading';
import GridItem from '../../Atoms/Grids/GridItem';
import KexLink from '../../Kex/KexLink';
import Button from '../../Atoms/Buttons/Button';
import Link from '../../Shared/Models/Link.interface';

interface linkObj {
  href: string;
  text: string;
}
interface LinkExt extends Link {
  linkText?: string;
  link?: linkObj;
}
interface HeroBlockModelExt extends HeroBlockModel {
  links: LinkExt[];
  header?: string;
  headerSize?: string;
  text?: string;
  buttonType?: string;
}

type PropType = {
  content: HeroBlockModelExt;
  blockId: any;
};

type HeaderObject = {
  [key: string]: any;
};

function HeroBlock({
  content: { header, headerSize, text, links, image, buttonType },
  blockId,
}: PropType) {
  const headerComponents: HeaderObject = {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    H6: H6,
  };
  const Header = headerComponents[headerSize as keyof HeaderObject];

  return (
    <GridItem layout="FullWidth">
      <Root data-litium-block-id={blockId}>
        <ImageContainer css={{ backgroundImage: `url(${image?.src})` }}>
          <ContentContainer>
            <SummaryContainer>
              <Header color="white">{header}</Header>
              <TextWrapper>
                <StyledPreamble>{text}</StyledPreamble>
                {links && (
                  <ButtonContainer>
                    {links.length > 0 &&
                      links.map(
                        (item) =>
                          item.link?.href && (
                            <KexLink key={item.link.href} {...item.link}>
                              <Button type={buttonType?.toLowerCase()} size="m">
                                <StyledP>
                                  {item.linkText || item.link.text}
                                </StyledP>
                              </Button>
                            </KexLink>
                          )
                      )}
                  </ButtonContainer>
                )}
              </TextWrapper>
            </SummaryContainer>
          </ContentContainer>
        </ImageContainer>
      </Root>
    </GridItem>
  );
}

const Root = styled('div', {
  flexWrap: 'wrap',
  display: 'flex',
  w: '100%',
});

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '500px',
  backgroundColor: 'rgba(24,24,24,0.4)',
  backgroundBlendMode: 'multiply',
  width: '100vw',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
});

const ContentContainer = styled('div', {
  display: 'flex',
  wh: '100%',
  maxW: '$contentMaxWidth',
  px: 4,
  justifyContent: 'center',
  '@mediaMinLarge': {
    px: 8,
  },
});

const TextWrapper = styled('div', {
  position: 'relative',
  overflowY: 'hidden',
  '& > p': {
    m: 0,
  },
});

const SummaryContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  backgroundColor: 'transparent',
  maxW: 248,
  textAlign: 'center',
  justifyContent: 'center',
});

const BackDrop = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  w: '100%',
  maxW: 248,
  t: 'auto',
  l: 'auto',
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 6,
  g: 4,
  flexWrap: 'wrap',
  '@mediaMinLarge': {
    g: 6,
  },
});

const Blur = styled('div', {
  position: 'absolute',
  b: 0,
  h: 8,
  w: '100%',
  backgroundImage: 'linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0))',
});

const ShowMoreButton = styled('button', {
  mx: 'auto',
  fontWeight: '$fw700',
  lineHeight: '$ls2',
  ls: '$ls08',
});

const StyledPreamble = styled('p', {
  m: 0,
  color: '#fff',
  fs: 8,
  fontWeight: '$fw600',
  lineHeight: '24px',
  letterSpacing: '1px',
});

const StyledP = styled('p', {
  m: 0,
});

export default HeroBlock;
